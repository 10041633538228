/*
 * @Description:
 * @Author: maozhixin
 * @Date: 2022-08-11 14:13:48
 * @LastEditors: zhangqi236 zhangqi236@countrygarden.com.cn
 * @LastEditTime: 2023-02-13 16:02:13
 */
import React from 'react';
import Menu from '../Menu/index';
import { useLocation } from 'react-router-dom';
import StyleDiv from './style';
import routes from '@/route.js';

// App.js
const Layout = React.memo(() => {
  let location = useLocation();
  const isUser = location.pathname.indexOf('userCenter') >= 0;

  const menus =
    (JSON.parse(localStorage.getItem('userInfo') || '{}')?.moduleList || [])[0]?.subMenus || [];
  const menuList = isUser ? [routes[1]] : menus;
  return (
    <StyleDiv>
      <div className="container">
        <div className="menu">
          <Menu menuList={menuList} theme={isUser ? 'light' : 'dark'} />
        </div>
        <div className="right_part"></div>
      </div>
    </StyleDiv>
  );
});
Layout.displayName = 'Layout';
export default Layout;
