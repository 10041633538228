// 菜单：思路是从路由文件中获取数据自动渲染菜单
import { useEffect, useState, memo } from 'react';
import _ from 'lodash';
import { Menu, message } from 'antd';
import type { MenuProps } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';
import { changeObjectKey, routesToBread } from '@/utils/common';
import logo from '@/assets/img/logo.png';
// import routes from '@/route.js';
import StyleDiv from './style';
import home from '@/assets/img/menu1.png';
import machine from '@/assets/img/menu5.png';
import system from '@/assets/img/system.png';
import farm from '@/assets/img/menu3.png';
import screen from '@/assets/img/screen.svg';
import agricul from '@/assets/img/agricul.png';
import irrigate from '@/assets/img/irrigate.png';
import plane from '@/assets/img/alyplane.png';
import { getByCompanyId } from '@/api/app';

type routeItem = {
  path: string;
  key?: string;
  element: any;
  name?: string;
  hideInMenu?: boolean;
  children?: routeItem[];
  parentId?: string;
};
const iconMap: any = {
  home: <img style={{ width: '18px', height: '18px' }} alt="首页" src={home} />,
  machine: <img style={{ width: '18px', height: '18px' }} alt="农机" src={machine} />,
  system: <img style={{ width: '18px', height: '18px' }} alt="系统管理" src={system} />,
  farm: <img style={{ width: '18px', height: '18px' }} alt="农场" src={farm} />,
  screen: <img style={{ width: '18px', height: '18px' }} alt="数据大屏" src={screen} />,
  agricul: <img style={{ width: '18px', height: '18px' }} alt="农情" src={agricul} />,
  irrigate: <img style={{ width: '18px', height: '18px' }} alt="灌溉" src={irrigate} />,
  plane: <img style={{ width: '32px', height: '12px' ,marginLeft:-8}} alt="无人机" src={plane} />,
};

const routeToMenu = (route: routeItem[]) => {
  if (!Array.isArray(route)) return [];
  let data = _.cloneDeep(route);
  data = data.map((item: any) => {
    delete item.parentId;
    item.icon = iconMap[item.css] || '';
    if(item.path=='/screen'){
      item.className='menu-screen'
    }
    if(item.path=='/irrigate'){
      item.className='menu-irrigate'
    }

    return changeObjectKey(item, {
      name: 'label',
      path: 'key',
      subMenus: 'children'
    });
  });
  data.forEach((item: routeItem) => {
    if (item.children?.length) {
      item.children = routeToMenu(item.children);
    }
    if (item.children?.length === 0) {
      delete item.children;
    }
  });
  return data;
};

let allMenuKeys: any[] = [];
const collectKeys = (list: any[]) => {
  const keys: string[] = [];
  const fun = (list: any[]) => {
    list.forEach((item) => {
      item.key && keys.push(item.key);
      item.children && fun(item.children);
    });
  };
  fun(list);
  return keys;
};

const IMenu = memo((props: { menuList: any; theme: 'light' | 'dark' }) => {
  const { menuList, theme } = props;
  const isUser = theme === 'light';
  let navigate = useNavigate();
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
  const [screenPrj,setScreenPrj]=useState<any>(null);

  let items = [];
  if (menuList?.length) {
    items = routeToMenu(menuList) as any;
    allMenuKeys = collectKeys(items);
  }
  const location = useLocation();
  useEffect(() => {
    let temp = routesToBread();
    // temp = temp?.filter((item) => !item.hideInMenu);
    temp = temp?.at(-1)?.path;
    const sltKeys = allMenuKeys.filter((key) => ('/' + temp).indexOf(key) !== -1);
    // setSelectedKeys(['/' + temp] as any);
    setSelectedKeys(sltKeys as any);
    getScreen();
  }, [location]);
  //获取大屏项目
  const getScreen=async()=>{
    const userInfo = JSON.parse(localStorage.getItem('userInfo') || '{}');
    const prj:any=await getByCompanyId({
      companyId:userInfo.companyId
    });
    setScreenPrj(prj[0].id);
  }
  // 菜单点击事件
  const onClick: MenuProps['onClick'] = async(item) => {
    if (item.key != '/screen') {
      navigate(item.key);
      setSelectedKeys(['/' + item.key]);
    }else{
      const auth = JSON.parse(localStorage.getItem('auth') || '{}');
      const organizationLevelName = localStorage.getItem('organizationLevelName');
      const token=auth.access_token;
      if(screenPrj!=null)
        window.open(`${process.env.REACT_APP_SCREEN}?projectId=${screenPrj}&token=${token}&organizationLevelName=${organizationLevelName}`);
      else
        message.info("该企业未定制数据大屏");
    }
  };
  
  return (
    <StyleDiv theme={theme}>
      <img
        onClick={() => {
          navigate('/home');
        }}
        className="logo"
        src={logo}
        alt="logo"
      />
      <Menu
        selectedKeys={selectedKeys}
        defaultOpenKeys={isUser ? ['userCenter'] : ['/farm']}
        theme={theme}
        onClick={onClick}
        style={{ width: 200 }}
        mode="inline"
        items={items}
      />
    </StyleDiv>
  );
});

IMenu.displayName = 'IMenu';
export default IMenu;
