import styled from 'styled-components';

const StyleDiv = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 200px;
  height: 100vh;
  color: #fff;
  background: ${(props: any) => (props.theme === 'dark' ? '#25252a' : '#fff')};
  overflow: auto;
  overflow-x:hidden;
  .ant-menu-sub.ant-menu-inline {
    background: ${(props: any) => (props.theme === 'dark' ? '#25252a' : '#fff')};
  }
  .logo {
    margin: 30px 0 20px 20px;
    width: 40px;
    height: 40px;
  }
  & > .ant-menu {
    img {
      width: 18px;
      height: 18px;
    }
    span {
      font-size: 16px;
    }
    .menu-screen{
      span{
        &::before{
          background:none !important;
        }
      }
    }
    .menu-irrigate{
      span{
        &::before{
          background:none !important;
        }
      }
    }
    .ant-menu-item {
      position: relative;
      margin-top: 0;
      margin-bottom: 0;
      height: 56px;
      line-height: 56px;
      color: ${(props: any) =>
        props.theme === 'dark' ? 'rgba(255, 255, 255, 0.8)' : 'rgba(0,0,0,.85)'};
      font-size: 16px;
      span {
        position: relative;
        overflow: unset !important;
        &::before {
          display: ${(props: any) => (props.theme === 'dark' ? 'unset' : 'none')};
          content: '';
          position: absolute;
          left: -28px;
          top: 50%;
          transform: translateY(-50%);
          width: 18px;
          height: 18px;
          background: #25252a;
          z-index: auto;
          opacity: 0.3;
        }
      }
      &.ant-menu-item-selected {
        position: relative;
        background: ${(props: any) =>
          props.theme === 'dark'
            ? 'linear-gradient(90deg, #444 0%, rgba(255, 255, 255, 0) 100%)'
            : '#3FD9A3'};
        &::before {
          content: '';
          position: absolute;
          width: 8px;
          height: 100%;
          background: #2fb755;
          left: 0;
          top: 0;
        }
        span {
          &::before {
            content: '';
            opacity: 0;
          }
        }
        &:after {
          display: none;
        }
      }
    }
    & > .ant-menu-submenu > .ant-menu > .ant-menu-item {
      /* padding-left: 24px !important; */
    }
  }
  .nongji {
    display: flex;
    align-items: center;
    color: rgba(255, 255, 255, 0.6);
    font-size: 15px;
    position: absolute;
    top: 146px;
    left: 24px;
    line-height: 34px;
    span {
      margin-left: 10px;
      display: inline-block;
      width: 108px;
      height: 1px;
      background-color: rgba(151, 151, 151, 0.4);
    }
  }
`;

export default StyleDiv;
