import React from 'react';
import ReactDOM from 'react-dom/client';
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';
import { createBrowserHistory } from 'history';
// import 'antd/dist/antd.min.css'; // or 'antd/dist/antd.less'
import 'antd/dist/antd.less'; // or 'antd/dist/antd.less'
import './initial.less';
import './antd_local.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ConfigProvider } from 'antd';
import zhCN from 'antd/es/locale/zh_CN';
import 'moment/locale/zh-cn';

const history = createBrowserHistory({ window });
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
const isProduction = process.env.NODE_ENV === 'production';

root.render(
  isProduction ? (
    <React.StrictMode>
      <HistoryRouter history={history as any}>
        <ConfigProvider locale={zhCN}>
          <App />
        </ConfigProvider>
      </HistoryRouter>
    </React.StrictMode>
  ) : (
    <HistoryRouter history={history as any}>
      <ConfigProvider locale={zhCN}>
        <App />
      </ConfigProvider>
    </HistoryRouter>
  )
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
