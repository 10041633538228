import React, { memo, Suspense, useEffect } from 'react';
import { useRoutes, useLocation, useNavigate } from 'react-router-dom';

import routes from './route';
import Fallback from '@/components/Fallback'; //eslint-disable-line
import { Spin } from 'antd';
import HxCrypto from '@/utils/Crypto';

HxCrypto.init({
  key: '2858310341595137',
  iv: '2890256383840258'
});

const App = memo(() => {
  const location = useLocation();
  const navigate = useNavigate();
  const isLogin = location.pathname === '/login';
  useEffect(() => {
    if (location.pathname === '/') {
      navigate('home');
    }
  }, [location.pathname, navigate]);

  let element = useRoutes(routes);
  return location.pathname === '/' ? null : (
    <Suspense fallback={isLogin ? <Spin /> : <Fallback />}>{element}</Suspense>
  );
});

App.displayName = 'App';

export default App;
