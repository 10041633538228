/*
 * @Author: miaojiawei
 * @Date: 2022-01-07 09:19:20
 * @LastEditTime: 2022-01-11 11:50:05
 * @LastEditors: miaojiawei
 * @Description:
 */
import request from '@/utils/request';

// 获取用户信息
export const getUserInfo = () => {
  return request(`/api-telematics-pc/v1/consumeruser/users/telematicsPortalCurrent`, {
    method: 'get'
  });
};

//获取大屏项目信息
export const getByCompanyId = (params:any) => {
  return request(`/api-customize-screen/v1/project/getByCompanyId`, {
    method: 'get',
    params
  });
};

