/*
 * @Author: zhangqi236 zhangqi236@countrygarden.com.cn
 * @Date: 2023-03-06 14:27:00
 * @LastEditors: zhangqi236 zhangqi236@countrygarden.com.cn
 * @LastEditTime: 2023-03-07 09:30:44
 * @FilePath: \hx-iov-client\src\components\Fallback\style.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import styled from 'styled-components';

const StyleDiv = styled.div`
  .container {
    display: flex;
    .menu {
      flex: 0 0 200px;
    }
    .right_part {
      flex: 1;
      width: calc(100% - 200px);
      background-color: #fafafa;
      .ant-breadcrumb {
        height: 40px;
        line-height: 40px;
        padding-left: 40px;
        li:not(:last-child) {
          cursor: pointer;
        }
      }
      main {
        height: calc(100% - 94px);
        overflow-y: auto;
        overflow-x: hidden;
        padding: 0 40px;
        background: #fafafa;
      }
    }
  }
`;

export default StyleDiv;
