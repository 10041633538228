import _ from 'lodash';
import CryptoJS from 'crypto-js';
import { logout } from '@/api/login';
import routeData from '@/route.js';
import Cookies from 'js-cookie';
// 将{text: 1}转换成{label: 1},changeObjectKey({text: 1}, {text: label})
export const changeObjectKey = (obj: Record<string, any>, keyMap: Record<string, string>) => {
  const result: any = _.cloneDeep(obj);
  Object.keys(keyMap).forEach((key) => {
    delete result[key];
    result[keyMap[key]] = obj[key];
  });
  return result;
};

// 路由转面包屑
export const routesToBread = () => {
  const flatRoute: any[] = [];
  const getFlatRoute = (route: any[]) => {
    route.forEach((item: any) => {
      if (item.children?.length) {
        getFlatRoute(item.children);
      }
      flatRoute.push(item);
    });
  };
  getFlatRoute(routeData);
  const { pathname = '' } = window.location;
  const pathArr = pathname.split('/').filter(Boolean);
  const result: any[] = [];
  pathArr.forEach((url, index) => {
    const item = (flatRoute.filter((v) => v.path === url) || [])[0] || {};
    result.push({
      path: pathArr.reduce((pre, cur, key) => (index >= key ? `${pre}/${cur}` : pre)),
      label: item.name
    });
  });
  return result;
};

/* 密码AES加密 */
export const AESencode = (srcs: string) => {
  // 加密
  // "123Qwe@"    zLeML+iFBmkcyeG6DMwMnA==
  const key = '2858310341595137';
  const iv = '2890256383840258';
  // 统一将传入的字符串转成UTF8编码
  // const srcs = CryptoJS.enc.Utf8.parse(password);
  const keyHex = CryptoJS.enc.Latin1.parse(key);
  const ivHex = CryptoJS.enc.Latin1.parse(iv);
  //
  const encode = CryptoJS.AES.encrypt(srcs, keyHex, {
    iv: ivHex,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.ZeroPadding
  });

  // console.log(encode, 'encode', encode.ciphertext.toString(CryptoJS.enc.Base64));
  const passCode = encode.ciphertext.toString(CryptoJS.enc.Base64);
  return passCode;
};

// 退出登录
export const handleOutlog = async () => {
  let access_token;
  let auth = localStorage.getItem('auth');
  if (auth) {
    access_token = JSON.parse(auth)?.access_token;
  }
  if (access_token) {
    Cookies.remove('access_token')
    await logout();
  }
  localStorage.setItem('auth', '');
  localStorage.setItem('userInfo', '');
  window.location.href = '/login';
};

// 获取过去一段时间范围，如获取过去7天的起始日期，则传参数7
export const getPastSomeDays = (num: number) => {
  const fun = (dayNum: number) => {
    const date = new Date();
    date.setDate(date.getDate() - dayNum + 1);
    const compleateMonthOrDay = (str: number | string) => (`${str}`.length === 2 ? str : `0${str}`);
    return `${date.getFullYear()}-${compleateMonthOrDay(date.getMonth() + 1)}-${compleateMonthOrDay(
      date.getDate()
    )}`;
  };
  return [fun(num), fun(1)];
};

export const getMonthDay = () => {
  const date = new Date();
  const compleateMonthOrDay = (str: number | string) => (`${str}`.length === 2 ? str : `0${str}`);
  const month = compleateMonthOrDay(date.getMonth() + 1);
  const day = compleateMonthOrDay(date.getDate());
  return `${month}-${day}`;
};

export const getWeek = () => {
  const a = new Array('日', '一', '二', '三', '四', '五', '六');
  const week = new Date().getDay();
  const str = '星期' + a[week];
  return str;
};
