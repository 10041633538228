/* Fetch的二次封装:让内置的fetch使用起来更加的方便「常用的默认值 & 约定的规范 & 对结果的处理...」 */
import _ from 'lodash';
import { handleOutlog } from '@/utils/common';
import { message } from 'antd';
import qs from 'qs';
import { useResolvedPath } from 'react-router-dom';

let inital = {
  method: 'GET',
  params: null,
  body: null,
  headers: {},
  cache: 'no-cache',
  credentials: 'include',
  responseType: 'JSON'
};

const baseUrl = process.env.REACT_APP_HTTP_SERVER;

export default function request(url: string, config: Record<string, any>) {
  // init params
  if (typeof url !== 'string') throw new TypeError('url must be required and of string type');
  if (!_.isPlainObject(config)) config = {};
  config = Object.assign({}, inital, config);
  let { method, params, body, headers, cache, credentials, responseType } = config; //eslint-disable-line

  if (params != null) {
    if (_.isPlainObject(params)) params = qs.stringify(params);
    url += `${url.includes('?') ? '&' : '?'}${params}`;
  }
  url = baseUrl + url;
  // 根据自己的需求来:body传递的是普通对象，我们今天项目需要传递给服务器的是URLENCODED格式，我们才处理它的格式；如果用户传递的本身就不是普通对象(例如:文件流、字符串、FORM-DATA...)，还是以用户自己写的为主...
  if (typeof body === 'object') {
    headers['Content-Type'] = 'application/json';
    body = JSON.stringify(body);
  } else if (typeof body === 'string') {
    try {
      // 是JSON字符串
      body = JSON.parse(body);
      headers['Content-Type'] = 'application/json';
    } catch (err) {
      // 不是JSON字符串:可以简单粗暴的按照URLECCODED格式字符串处理
      headers['Content-Type'] = 'application/json';
    }
  }

  // 类似于AXIOS中的请求拦截器，例如：我们每一次发请求都需要携带TOKEN信息
  let access_token;
  let auth = localStorage.getItem('auth');
  if (auth) {
    access_token = JSON.parse(auth)?.access_token;
  }
  if (access_token) headers['Authorization'] = `Bearer ${access_token}`;

  // 把config配置成为fetch需要的对象
  config = {
    method: method.toUpperCase(),
    headers,
    credentials,
    cache
  };
  if (/^(POST|PUT|PATCH)$/i.test(method) && body != null) config.body = body;

  // 发送请求
  return fetch(url, config)
    .then(async (response: any) => {
      let { status, message, result, code } = await response.json();
      // 只要状态码是以2或者3开始的，才是真正的获取成功
      // let result;
      // if (status >= 200 && status < 400) {
      //   switch (responseType.toUpperCase()) {
      //     case 'JSON':
      //       result = response.json();
      //       break;
      //     case 'TEXT':
      //       result = response.text();
      //       break;
      //     case 'BLOB':
      //       result = response.blob();
      //       break;
      //     case 'ARRAYBUFFER':
      //       result = response.arrayBuffer();
      //       break;
      //     default:
      //       break;
      //   }
      // }
      if (status === 200) {
        return result;
      }
      return Promise.reject({
        info: 'STATUS ERROR',
        status,
        code,
        message
      });
    })
    .catch((reason) => {
      console.log(reason);

      if (reason && reason.info === 'STATUS ERROR') {
        message.error(reason.message);
        // @1 状态码错误
        switch (reason.code) {
          case 400:
            // ...
            break;
          case 401:
            localStorage.setItem('auth', '');
            handleOutlog();
            break;
          case 404:
            // ...
            break;
          default:
            break;
        }
      } else if (!navigator.onLine) {
        // @2 网络中断
        // ...
      } else {
        // @3 请求被终止
        // ...
      }
      return Promise.reject(reason);
    });
}
