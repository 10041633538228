/*
 * @Description:
 * @Author: maozhixin
 * @Date: 2022-08-09 15:44:03
 * @LastEditors: zhangqi236 zhangqi236@countrygarden.com.cn
 * @LastEditTime: 2023-03-30 17:21:52
 */
import * as React from 'react';
import menu1Icon from '@/assets/img/menu1.png';
import menu2Icon from '@/assets/img/menu2.png';
import menu3Icon from '@/assets/img/menu3.png';
import menu4Icon from '@/assets/img/menu4.png';
import menu5Icon from '@/assets/img/menu5.png';

const Layout = React.lazy(() => import('./components/Layout'));
const Login = React.lazy(() => import('./pages/Login'));
const Home = React.lazy(() => import('./pages/Home'));
const Dispatch = React.lazy(() => import('./pages/Dispatch'));
const Config = React.lazy(() => import('./pages/Dispatch/config'));
const CreateWork = React.lazy(() => import('./pages/Dispatch/createWork'));
const WorkRecord = React.lazy(() => import('./pages/WorkRecord'));
const ViewHistory = React.lazy(() => import('./pages/WorkRecord/ViewHistory'));
const WorkAssess = React.lazy(() => import('./pages/WorkRecord/WorkAssess'));
const ProbRecord = React.lazy(() => import('./pages/ProbRecord'));
const TroubleRecord = React.lazy(() => import('./pages/TroubleRecord'));
const Organization = React.lazy(() => import('./pages/Organization'));
const PostManage = React.lazy(() => import('./pages/PostManage'));
const UserCenter = React.lazy(() => import('./pages/UserCenter/index'));
const UserManage = React.lazy(() => import('./pages/System/User'));
const UserManageQuery = React.lazy(() => import('./pages/System/User/query.tsx'));
const RoleManage = React.lazy(() => import('./pages/System/Role'));
const MachineManage = React.lazy(() => import('./pages/machineManage'));
const CropMonitor = React.lazy(() => import('./pages/Agricul/CropMonitor'));
const PestMonitor = React.lazy(() => import('./pages/Agricul/PestMonitor'));
const PlaneTrack = React.lazy(() => import('./pages/Plane/Track'));
const PlanePatorl = React.lazy(() => import('./pages/Plane/Patrol'));
const NoMatch = React.lazy(() => import('./pages/NoMatch'));
const FarmInfo = React.lazy(() => import('./pages/Farm/Info'));
const FarmLandManage = React.lazy(() => import('./pages/Farm/Land'));
const DeviceManage = React.lazy(() => import('./pages/Farm/Device'));
const LandAudit = React.lazy(() => import('./pages/Farm/Land/Audit'));
const MachineLand = React.lazy(() => import('./pages/Farm/Land/Land/MachineLand'));
const Irrigate = React.lazy(() => import('./pages/Irrigate'));
const Personalization = React.lazy(() => import('./pages/System/Personalization'));
const AgriculturaMonitor = React.lazy(() => import('./pages/Agricul/AgriculturaMonitor'));
const JobTrack = React.lazy(() => import('./pages/Plane/Track/JobTrack'));
const DeviceTrack = React.lazy(() => import('./pages/Dispatch/track'));
const routes = [
  {
    path: '/',
    element: <Layout />,
    children: [
      {
        icon: <img style={{ width: '18px', height: '18px' }} alt="首页" src={menu1Icon} />,
        path: 'home',
        name: '首页',
        element: <Home />
      },
      {
        name: '农场',
        path: 'farm',
        icon: <img style={{ width: '18px', height: '18px' }} alt="农场" src={menu2Icon} />,
        children: [
          {
            path: 'info',
            name: '农场概览',
            element: <FarmInfo />
          },
          {
            path: 'land',
            name: '地块管理',
            children: [
              { index: true, hideInMenu: true, element: <FarmLandManage /> }, 
              {
                path: 'audit',
                element: <LandAudit />,
                hideInMenu: true,
                name: '地块审核',
              },
              {
                path: 'machine',
                element: <MachineLand />,
                hideInMenu: true,
                name: '农机绕田建图',
              }
            ]
          },
          {
            path: 'device',
            name: '设备管理',
            element: <DeviceManage />
          },
          {
            path: 'prop',
            name: '农资管理'
          }
        ]
      },
      {
        name: '农机',
        path: 'machine',
        icon: <img style={{ width: '18px', height: '18px' }} alt="农机" src={menu5Icon} />,
        children: [
          {
            path: 'dispatch',
            // element: <Dispatch />,
            name: '作业调度',
            icon: <img style={{ width: '18px', height: '18px' }} alt="作业调度" src={menu2Icon} />,
            children: [
              { index: true, hideInMenu: true, element: <Dispatch /> },
              {
                path: 'config',
                name: '配置信息',
                element: <Config />,
                hideInMenu: true
              },
              {
                path: 'createWork',
                name: '新建作业',
                element: <CreateWork />,
                hideInMenu: true
              },
              {
                path: 'continueWork',
                name: '续航作业',
                element: <CreateWork />,
                hideInMenu: true
              },
              {
                path: 'track',
                name: '农机轨迹',
                element: <DeviceTrack />,
                hideInMenu: true
              }
            ]
          },
          {
            path: 'workRecord',
            name: '作业记录',
            icon: <img style={{ width: '18px', height: '18px' }} alt="作业记录" src={menu3Icon} />,
            children: [
              { index: true, hideInMenu: true, element: <WorkRecord /> },
              {
                path: 'workassess',
                name: '作业评估',
                element: <WorkAssess />,
                hideInMenu: true
              }
            ]
          },
          {
            path: 'probRecord',
            element: <TroubleRecord />,
            name: '故障记录',
            icon: <img style={{ width: '18px', height: '18px' }} alt="故障记录" src={menu4Icon} />
          },
          {
            path: 'machineManage',
            element: <MachineManage />,
            name: '机具管理',
            icon: <img style={{ width: '18px', height: '18px' }} alt="机具管理" src={menu4Icon} />
          },
        ]
      },
      {
        name: '农情',
        path: 'agricul',
        icon: <img style={{ width: '18px', height: '18px' }} alt="农情" src={menu2Icon} />,
        children: [
          { path: 'monitor', element: <AgriculturaMonitor />, name: '农情监控' },
          { path: 'growth', element: <CropMonitor />, name: '苗情监控' },
          { path: 'pestLevel', element: <PestMonitor />, name: '虫情监测' }
        ]
      },
      {
        name: '无人机',
        path: 'plane',
        icon: <img style={{ width: '18px', height: '18px' }} alt="无人机" src={menu2Icon} />,
        children: [
          {
            path: 'track',
            // element: <PlaneTrack />,
            name: '作业轨迹',
            children: [
              { index: true, hideInMenu: true, element: <PlaneTrack /> },
              { path: 'jobTrack', element: <JobTrack />, name: '无人机作业轨迹', hideInMenu: true }
            ]
          },
          { path: 'planePatorl', element: <PlanePatorl />, name: '巡田监测' }
        ]
      },
      {
        name: '灌溉',
        path: 'irrigate',
        icon: <img style={{ width: '18px', height: '18px' }} alt="灌溉" src={menu2Icon} />,
        element: <Irrigate />
      },
      {
        name: '数据大屏',
        path: 'screen',
        icon: <img style={{ width: '18px', height: '18px' }} alt="大屏系统" src={menu4Icon} />,
        element: <NoMatch />
      },
      {
        name: '系统管理',
        path: 'system',
        icon: <img style={{ width: '18px', height: '18px' }} alt="系统管理" src={menu5Icon} />,
        children: [
          {
            path: 'organization',
            element: <Organization />,
            name: '组织管理',
            icon: <img style={{ width: '18px', height: '18px' }} alt="组织管理" src={menu4Icon} />
          },
          {
            path: 'postManage',
            element: <PostManage />,
            name: '岗位管理',
            icon: <img style={{ width: '18px', height: '18px' }} alt="岗位管理" src={menu4Icon} />
          },
          {
            path: 'user',
            // element: <UserManage />,
            name: '用户管理',
            icon: <img style={{ width: '18px', height: '18px' }} alt="作业调度" src={menu2Icon} />,
            children: [
              { index: true, hideInMenu: true, element: <UserManage /> },
              {
                path: 'query',
                hideInMenu: true,
                element: <UserManageQuery />,
                name: '查看用户'
              }
            ]
          },
          {
            path: 'role',
            element: <RoleManage />,
            name: '角色管理',
            icon: <img style={{ width: '18px', height: '18px' }} alt="角色管理" src={menu3Icon} />,
            children: []
          },
          {
            path: 'setting',
            element: <Personalization />,
            name: '个性化设置',
            icon: (
              <img style={{ width: '18px', height: '18px' }} alt="个性化设置" src={menu3Icon} />
            ),
            children: []
          }
        ]
      },
      {
        path: 'viewhistory',
        hideInMenu: true,
        element: <ViewHistory />
      },
      { path: '', hideInMenu: true, redirect: 'home' }
    ]
  },
  {
    name: '用户中心',
    path: '/userCenter',
    element: <UserCenter />
  },
  {
    path: 'login',
    name: '登录',
    hideInMenu: true,
    element: <Login />
  },
  { path: '*', hideInMenu: true, element: <NoMatch /> }
];

export default routes;
