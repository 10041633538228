/*
 * @Author: miaojiawei
 * @Date: 2022-01-07 09:19:20
 * @LastEditTime: 2022-01-11 11:50:05
 * @LastEditors: miaojiawei
 * @Description:
 */
import request from '@/utils/request';

export const capatch = () => {
  return request(`/api-telematics-pc/v1/loginmanager/oauth/capatch`, {
    method: 'get'
  });
};

export type LoginParamsTy = {
  username: string;
  password: string;
  code: string;
  uuid: string;
};
// 登录
export const login = (body: LoginParamsTy) => {
  return request(`/api-telematics-pc/v1/loginmanager/oauth/telematicsClient/token`, {
    method: 'POST',
    headers: { client_id: 'telematicsWeb', client_secret: 'telematicsWeb' },
    body
  });
};

// 登录
export const logout = () => {
  return request(`/api-telematics-pc/v1/loginmanager/oauth/remove/token`, {
    method: 'POST'
  });
};


// 登录
export const getUserByToken = (params:any) => {
  return request(`/api-telematics-pc/v1/loginmanager/oauth/get/token`, {
    method: 'POST',
    params
  });
};
